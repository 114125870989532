<template>
  <div v-if="isDataLoaded">
    <v-footer app dark absolute>
      <v-container>
        <v-row justify="start">
          <v-col cols="9" class="pa-0 ma-0">
            <template v-for="(link, i) in links">
              <span :key="i">
                <span v-if="i != 0"> &nbsp; {{ delimiter }} &nbsp; </span>
                <a
                  :class="link.class"
                  target="_blank"
                  @click="handleClick(link, $router)"
                  :href="link.href ? link.href : null"
                  >{{ link.text }}</a
                >
              </span>
            </template>
          </v-col>
          <v-col cols="3" class="pa-0 ma-0">
            <span class="grey--text">
              &copy; {{ new Date().getFullYear() }} Sony Electronics. All Rights Reserved.
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data: () => ({
    delimiter: "|",
    links: [],
    isDataLoaded: false
  }),
  mounted() {
    this.initializeLinks();
  },
  computed: {
    ...mapGetters(["selectedProgram"]),
    documentTags() {
      return {
        "SONY-CEUR": {
          "Terms and Conditions": "SONY-CEUR/termsandconditions/dashboard"
        },
        "SONY-CCPR": {
          "Terms and Conditions": "SONY-CCPR/termsandconditions/dashboard"
        }
      };
    }
  },
  watch: {
    selectedProgram() {
      this.initializeLinks();
    }
  },
  methods: {
    handleClick(link, router) {
      if (link.name) {
        router.push({ name: link.name });
      }
      if (link.href) {
        window.open(link.href, "_blank");
      }
    },
    fetchDocumentUrl(tag) {
      return this.$api
        .get(`/api/uploads/byProgramGroup/${this.selectedProgram.programGroup.id}/public`)
        .then(response => {
          const document = response.data.find(upload => {
            return upload.tag?.trim() === tag.trim();
          });
          return document ? document.href : "";
        })
        .catch(error => {
          console.error("Failed to fetch terms and conditions:", error);
          return "";
        });
    },
    initializeLinks() {
      if (this.$route.name === "ceurLanding") {
        this.links = this.ceurLandingLinks();
        this.isDataLoaded = true;
        return;
      }

      const programTypeKey = this.selectedProgram?.programKey;
      if (programTypeKey in this.documentTags) {
        const tags = this.documentTags[programTypeKey];
        const linkPromises = Object.keys(tags).map(docType => {
          return this.fetchDocumentUrl(tags[docType]).then(url => ({
            text: docType,
            href: url
          }));
        });

        Promise.all(linkPromises).then(dynamicLinks => {
          let staticLinks = this.programSpecificLinks(programTypeKey);
          // Update the static links with dynamic hrefs where applicable
          staticLinks = staticLinks.map(link => {
            const dynamicLink = dynamicLinks.find(dLink => dLink.text.toLowerCase() === link.text.toLowerCase());
            return dynamicLink ? { ...link, href: dynamicLink.href } : link;
          });
          this.links = staticLinks;
          this.isDataLoaded = true;
        });
      } else {
        this.links = this.programSpecificLinks(programTypeKey);
        this.isDataLoaded = true;
      }
    },
    programSpecificLinks(programTypeKey) {
      switch (programTypeKey) {
        case "SONY-CEUR":
          return this.ceurLinks();
        case "SONY-CCPR":
          return this.ccprLinks();
        case "SONY-EUR":
          return this.eurLinks();
        case "SONY-CPR":
          return this.cprLinks();
        default:
          return [];
      }
    },
    eurLinks() {
      return [
        {
          text: "End User",
          href: "http://www.sony.com/prorebates",
          class: "program-title"
        },
        {
          text: "Service & Support",
          name: "eurListServiceAndSupport"
        },
        {
          text: "Professional Website",
          href: "https://pro.sony/ue_US/home"
        },
        {
          text: "SEL Privacy Policy",
          href: "https://electronics.sony.com/privacy-policy"
        },
        {
          text: "CA Privacy Notice",
          href: "https://electronics.sony.com/privacy-policy#DataPractices"
        },
        {
          text: "Canadian Privacy Policy",
          href: "http://corporate.sony.ca/view/privacy.htm"
        }
      ];
    },
    cprLinks() {
      return [
        {
          text: "End User",
          href: "http://www.sony.com/prorebates"
        },
        {
          text: "Dealer Source",
          href: "https://dealersource.sel.sony.com/"
        },
        {
          text: "Professional Website",
          href: "https://pro.sony/ue_US/home"
        },
        {
          text: "SEL Privacy Policy",
          href: "https://electronics.sony.com/privacy-policy"
        },
        {
          text: "CA Privacy Notice",
          href: "https://electronics.sony.com/privacy-policy#DataPractices"
        },
        {
          text: "Canadian Privacy Policy",
          href: "http://corporate.sony.ca/view/privacy.htm"
        }
      ];
    },
    ceurLinks() {
      return [
        {
          text: "Terms and Conditions",
          href: "#"
        },
        {
          text: "Terms of Service",
          href: "https://electronics.sony.com/terms-of-use"
        },
        {
          text: "SEL Privacy Policy",
          href: "https://electronics.sony.com/privacy-policy"
        },
        {
          text: "CA Privacy Notice",
          href: "https://electronics.sony.com/privacy-policy#DataPractices"
        },
        {
          text: "See Authorized Resellers",
          href: "https://electronics.sony.com/retailers"
        },
        {
          text: "Product Support",
          href: "https://www.sony.com/electronics/support?cpint=d2c-supportmenu"
        },
        {
          text: "PRO End User",
          href: "https://rebate.pro.eur.sony.com/?path=%2Fprorebates"
        }
      ];
    },
    ccprLinks() {
      return [
        {
          text: "Terms and Conditions",
          href: "#"
        },
        {
          text: "Terms of Service",
          href: "https://electronics.sony.com/terms-of-use"
        },
        {
          text: "SEL Privacy Policy",
          href: "https://electronics.sony.com/privacy-policy"
        },
        {
          text: "CA Privacy Notice",
          href: "https://electronics.sony.com/privacy-policy#DataPractices"
        },
        {
          text: "See Authorized Resellers",
          href: "https://electronics.sony.com/retailers"
        },
        {
          text: "Product Support",
          href: "https://www.sony.com/electronics/support?cpint=d2c-supportmenu*/"
        }
      ];
    },
    ceurLandingLinks() {
      return [
        {
          text: "Terms of Service",
          href: "https://electronics.sony.com/terms-of-use"
        },
        {
          text: "SEL Privacy Policy",
          href: "https://electronics.sony.com/privacy-policy"
        },
        {
          text: "CA Privacy Notice",
          href: "https://electronics.sony.com/privacy-policy#DataPractices"
        },
        {
          text: "See Authorized Resellers",
          href: "https://electronics.sony.com/retailers"
        },
        {
          text: "Product Support",
          href: "https://www.sony.com/electronics/support?cpint=d2c-supportmenu*/"
        }
      ];
    }
  }
};
</script>
